import InfoIcon from '@mui/icons-material/Info';
import { useContext, useEffect, useState } from 'react';
import {Drawer} from 'antd'
import { Appstate } from '../App';
import { Pie, Doughnut, Radar } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale, PointElement, LineElement, Filler } from "chart.js";
import { useTimer } from "react-timer-hook";
import moment from 'moment';
import {TailSpin} from 'react-loader-spinner'
import LockerABI from '../artifacts/Locker/Lock.sol/Locker.json'
import {ethers} from 'ethers'

ChartJS.register(ArcElement, Tooltip, Legend, RadialLinearScale, PointElement, LineElement, Filler);

function MyTimer2({ expiryTimestamp }) {
    const {
      seconds,
      minutes,
      hours,
      days
    } = useTimer({ expiryTimestamp});
  
    return <p><span>{days.toString()} Days</span>, <span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
  }

const lqOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
          position: 'right', // Position the legend to the right of the chart
          align: 'center', // Align the legend items to the start (top) of the legend box
          labels: {
            padding: 10, // Add padding between legend items
            boxHeight: 20, // Set the height of each legend item
            usePointStyle: true // Use point style for legend items (if applicable)
          }
        }
      }
  };

const LqGraph = () => {
    const useAppState = useContext(Appstate)
    const [show, setShow] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [data, setData] = useState({
        btch: 0,
        usdt: 0
    })
    const [time, setTime] = useState(0);
    const [lqData, setLqData] = useState({
        labels: ['Locked (100.0%)', 'Unlocked (0.0%)', 'Burned (0.0%)'],
        datasets: [{
          label: 'Locked (%) ',
          data: [100, 0, 0],
          backgroundColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)',
          ]
        }]
      });
      

      useEffect(() => {
        async function getData() {
            let _bal = await useAppState.tokenContract2.balanceOf("0xE856A742d63ABa9bD7E63C2530fD194075F71F47");
            let _bal2 = await useAppState.tokenContract.balanceOf("0xE856A742d63ABa9bD7E63C2530fD194075F71F47");
            setData({
                btch: Number(useAppState.convert(_bal)).toFixed(2),
                usdt: Number(useAppState.convert(_bal2)).toFixed(2)
            })
              setShow(true);

              let provider = new ethers.providers.Web3Provider(window.ethereum);
              let signer = provider.getSigner();
              let lockerAddr = "0xa6840090fCFf677376Ed4759D73448cc9104617d"
              let locker = new ethers.Contract(lockerAddr, LockerABI.abi, signer);

              let _time = await locker.unlockTime();
              setTime(Number(_time) * 1000);
              setShowTimer(true);
        }
        getData();
      },[])


  return (
    <>
        <div className='mt-4'>
            {show ?
            <Doughnut data={lqData} options={lqOptions} />
            : <TailSpin height={20} color='gray' />}
        </div>
        <div className='font-medium text-gray-700 mt-6'>
            <p>🔸 <span className='text-red-500'>Total Liquidity :</span> ${Number(data.usdt * 2).toFixed(2)}</p>
            <p>🔸 <span className='text-orange-500'>Token A :</span> {data.btch} BTCH</p>
            <p>🔸 <span className='text-green-500'>Token B :</span> {data.usdt} USDT</p>
        </div>
        <div className='mt-4 flex items-center font-semibold text-gray-700'>
            <p className='font-semibold text-sky-500 mr-1'>Unlock Countdown : </p>
            {showTimer ?
                <MyTimer2 expiryTimestamp={time} />
            : null}
        </div>
        <a href='https://polygonscan.com/address/0xa6840090fCFf677376Ed4759D73448cc9104617d' target='_blank'><p className='text-gray-500 cursor-pointer px-2 py-1 bg-gray-300 bg-opacity-60 mt-2 rounded-xl font-medium profile'>Locker Contract Polygonscan: <span className='text-purple-500'>0xa6840...617d</span></p></a>
        <a href='https://polygonscan.com/tx/0xadeb1b06bfa2e949c78580cffd1083de0b4eed1e015dd635cbf6a6b44a3bd22f' target='_blank'><p className='text-gray-500 cursor-pointer px-2 py-1 bg-gray-300 bg-opacity-60 mt-2 rounded-xl font-medium profile'>Liquidity Lock txn Polygonscan: <span className='text-purple-500'>0xadeb...d22f</span></p></a>
        <p className='text-gray-700 mt-2 px-2 py-1 bg-gray-300 bg-opacity-30 rounded-lg'>The Liquidity of BTCH is Locked in contract developed by Developers at BTCHelp to ensure the security by not relying on third party sources. Locker contract of BTCH is 100% Genuine and Opensourced. Users can check out on contract by above link. and verify the Unlock time as source code is Verified ✅</p>
    </>
  )
}

export default LqGraph
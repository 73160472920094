import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useState, useEffect, createContext } from "react";
import { ethers } from "ethers";
import Dashboard from './Components/Dashboard'
import Register from './Components/Register'
import Homepage from './Components/Homepage'
import Teams from './Components/Teams'
import Wallet from './Components/Wallet'
import BTChelp from '../src/artifacts/contracts/BTChelp.sol/BTChelp.json'
import BTCHSwap from '../src/artifacts/contracts/BTCHSwap.sol/BTCHSwap.json'
import Fee from '../src/artifacts/contracts/taxFee.sol/TaxFee.json'
import DepositHistory from "./Components/DepositHistory";
import DownlineUsers from "./Components/DownlineUsers";
import Admin from "./Components/Admin";
import Swap from "./Components/Swap";

const Appstate = createContext();

const erc20Abi = [
  "function balanceOf(address) view returns (uint256)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address, uint256) returns (bool)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address, uint256) returns (bool)",
  "function transferFrom(address, address, uint256) returns (bool)",
];

function App() {
  const {ethereum} = window;
  const [walletAddress, setWalletAddress] = useState("");
  const [change, setChange] = useState(0);
  const [show, setShow] = useState(false);
  const [isPc, setIsPc] = useState(false);
  const [rank, setRank] = useState(["Participant", "JR. Manager", "SR. Manager", "100 Manager", "500 Manager", "1K Manager", "10K Manager"])
  const [open, setOpen] = useState(false);
  const [req, setReq] = useState([{
    isAuto: false,
    sno: 0,
    donar: "",
    receiver: "",
    amount: 0,
    start: 0,
    isCompleted: false,
    depositIndex: 0,
    globalIndex: 0,
    endTime: 0,
    amtPaid: false,
  }])
  const [isAdmin, setIsAdmin] = useState(true);
  
  const convert = (amount) => {
    return ethers.utils.formatUnits(amount.toString(), "mwei");
  }
  
  const btch = "0xfd96bf0a43ce3e676d064cd04035b3b849365656";
  const usdt = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
  const mainAddr = "0xD21fb7211719720d1353EaFD72a4a7d4774777C1";
  const defaultRefer = "0xD21fb7211719720d1353EaFD72a4a7d4774777C1";
  const swapAddr = "0x84248866f3Ada3E282abEBDA657Abfc4a272e2De";
  const feeAddr = "0x23D823D5e8CDfd55bb0e02aFE31f979d3fB8105F";

  let provider;
  if(ethereum !== undefined) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    provider = new ethers.providers.JsonRpcProvider();
  }

  const signer = provider.getSigner();
  const contract = new ethers.Contract(mainAddr, BTChelp.abi, signer);
  const tokenContract = new ethers.Contract(usdt, erc20Abi, signer);
  const tokenContract2 = new ethers.Contract(btch, erc20Abi, signer);
  const swapContract = new ethers.Contract(swapAddr, BTCHSwap.abi, signer);
  const feeContract = new ethers.Contract(feeAddr, Fee.abi, signer);

  useEffect(() => {
    const isMdScreen = window.matchMedia("(min-width: 768px)").matches;
    if (isMdScreen) {
      setShow(true);
      setIsPc(true);
    }
  },[])

  useEffect(() => {
    async function req() {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setWalletAddress(accounts[0].toLowerCase());
      let arr = [
        "0xdC88552C71e33c5cdb2563Eee3d9efFC17aFf695", 
        "0xDbBB9850B895F19840529BD8DFF0BBF07c7D4117", 
        "0x8F26A8e3704E4924Ae70bC340160b542aE45deA2",
        "0x2d921bd910FE947c918dbA626F02d150C167dAA5",
        "0xE9166Bf5f532e04d5023d47515f8319Ab8bFf04d",
        "0x96040A5d5820CC10e11CE66731c883183239557b",
        "0x23B86c0b35335Cb1359F7B24574867147f936787",
        "0x7EA9b9f493A502BbD06cF371Ca568D97F6e0528E",
        "0x767FC7CA894bbC48C432d40fD6F547B00857BF2b",
        "0x4e8bbb037Cd8e47F5c64fE1E408FDF47cca5C6d5",
        "0x64D8c6A46443AcB6B22867597b794427a33EC404",
        "0xB4674764232f1b464D79b6E341E342cA556B1907",
        "0x480a33454b681F3BCecaC59838de3761730AafD4",
      ];
      for(let i=0; i<arr.length; i++) {
        if(accounts[0].toLowerCase() == arr[i].toLowerCase()) {
          setIsAdmin(true);
        }
      }
      // setWalletAddress(("0xeDdf983790e63f439fD07CdaFea84c30A735AA77").toLowerCase());
    }
    req();
  }, []);

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0].toLowerCase());
    });
  }

  return (
    <Appstate.Provider value={{usdt, req, setReq, btch, mainAddr, contract, swapContract, swapAddr, feeContract, feeAddr, defaultRefer, tokenContract, tokenContract2, walletAddress,setWalletAddress, setChange, change, convert, show, setShow, rank, setRank, isPc, open, setOpen}}>
      {isAdmin ?
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/register" element={<Register />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/deposithistory" element={<DepositHistory />} />
            <Route path="/downlineusers/:level" element={<DownlineUsers />} />
            <Route path="/swap" element={<Swap />} />
            <Route path="/dist" element={<Admin />} />
        </Routes>
      :
      <div className="flex flex-col h-[100vh] w-full justify-center items-center">
        <h1 className="text-2xl text-red-500 font-semibold">Site Under Maintainance</h1> 
        <p className="text-8xl">👨‍💻</p>
        {/* <p className="text-blue-500">Will be live within 24 hours</p> */}
      </div>
      }
    </Appstate.Provider>
  );
}

export default App;
export {Appstate}

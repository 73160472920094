import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Carousel, message } from 'antd';
import { Modal } from "antd";
import { Appstate } from '../App';

const Homepage = () => {
  const useAppState = useContext(Appstate);
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);

  const login = async () => {
    try {
      let user = await useAppState.contract.userInfo(useAppState.walletAddress);
      if(Number(user.referrer) == "0x0000000000000000000000000000000000000000") {
        message.warning("Register First");
      } else {
        message.success("Sucessfully Login");
        navigate('/dashboard');
      }
    } catch (error) {
      message.error(error.reason)
    }
  }

  return (
    <div className='relative h-screen overflow-x-hidden'>
        <header className='flex bg-[#18191a] z-40 shadow-lg p-5 justify-between items-center w-full h-[70px] sticky top-0'>
          <h1 className='text-3xl font-bold'><span className='text-red-400'>BTC</span><span className='ml-1 text-cyan-300'>help</span></h1>
          <div className='flex justify-center items-center'>
            <p onClick={login} className='bg-lime-500 py-2 px-6 cursor-pointer font-semibold text-sm rounded-md'>Login</p>
          </div>
        </header>    

        <div className='relative flex justify-center h-screen items-center'>
          <img src='background.png' className='absolute top-0 hidden md:block h-full w-full' />
          <img src='mobbg.png' className='absolute block md:hidden -top-12 h-full w-full' />
          <img src='bubble.png' className='absolute h-[400px] rotate mb-[130px] md:mb-[80px]' />
          <img src='logo.png' className='absolute h-[180px] mb-[130px] md:mb-[80px] animate-pulse' />
        </div>

        <div className='relative flex justify-start items-center h-[500px] md:h-[700px] -translate-y-16'>
          <img src='planet.webp' className='absolute top-8 -right-24 md:right-0 h-full opacity-70 md:opacity-100 -z-30' />
          <div className='w-full md:w-1/2 md:mt-0 mt-[600px] px-5 text-xl font-semibold'>
          <span className='txtgradient'>BTChelp</span> is an innovative decentralized network platform that leverages the transformative potential of smart contracts to redefine the dynamics of modern marketing. Built on a foundation of <span className='txtgradient'>blockchain technology</span>, <span className='txtgradient'>BTChelp</span> creates a secure and transparent ecosystem. 
          <Link to={'/register'} className='mt-3 bg-blue-500 w-32 flex justify-center items-center rounded-lg p-3 hover:bg-blue-600 text-sm cursor-pointer'>Register Now</Link>
          <div className='mt-16 md:mt-0 flex flex-col md:flex-row justify-between w-full p-5 md:absolute -bottom-0 md:-bottom-14'>
            <div className='flex flex-col items-center md:w-1/3 w-full blackbg rounded-xl p-5'>
              <EmojiEventsIcon fontSize='large' /> 
              <p>Rewards</p>
              <p className='text-sm text-gray-500 mt-3 text-center'>Our decentralized platform offers a variety of rewards for users who contribute to our ecosystem.</p>
            </div>
            <div className='mt-4 md:mt-0 ml-0 md:ml-4 flex flex-col items-center md:w-1/3 w-full blackbg rounded-xl p-5'>
              <VpnLockIcon fontSize='large' /> 
              <p>Secure</p>
              <p className='text-sm text-gray-500 mt-3 text-center'>Our platform is built on smart contracts, providing a high level of security for all users.</p>
            </div>
            <div className='mt-4 md:mt-0 ml-0 md:ml-4 mr-0 md:mr-5 flex flex-col items-center md:w-1/3 w-full blackbg rounded-xl p-5'>
              <PersonOffIcon fontSize='large' /> 
              <p>No Admin</p>
              <p className='text-sm text-gray-500 mt-3 text-center'>Smart contracts operate without the need for admin, as they are self-executing.</p>
            </div>
          </div>
          </div>
        </div>

        <div className='flex flex-col mb-8 items-center bggradient2 relative mt-[530px] md:mt-36 p-4 h-[670px] md:h-[400px]'>
          <div className='shadow-lg md:w-3/4 w-full p-4 bg-[#242526] rounded-lg'>
          <Carousel autoplay>
            <div className='w-full'>
              <div className='flex flex-col items-center w-full pb-8 pt-4'>
                <img src='blockchain.png' className='h-32' />
                <p className='txtgradient text-2xl font-bold'>Blockchain</p>
                <p className='mt-3 px-3 text-[#dad7d7]'>Blockchain is a decentralized and distributed digital ledger that records and verifies transactions across multiple computers or nodes. It is a foundational technology that underlies cryptocurrencies like Bitcoin, but its applications go beyond digital currencies. In a traditional centralized system, transactions and data are typically stored in a single, trusted entity such as a bank or a government. In contrast, a blockchain network consists of a network of computers, known as nodes, that collectively maintain a shared and synchronized ledger. Each transaction is grouped into a "block" and added to a chain of previous blocks, forming a chronological sequence of records.</p>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex flex-col items-center w-full pb-10 pt-4'>
                <img src='matictoken.png' className='h-32' />
                <p className='txtgradient text-2xl font-bold'>Polygon Chain</p>
                <p className='mt-3 px-3 text-[#dad7d7]'>Polygon (previously known as Matic Network) is a scaling and infrastructure framework for Ethereum. It is designed to address some of the scalability and usability issues of the Ethereum blockchain by providing a sidechain solution. Polygon aims to enhance the functionality and performance of Ethereum by offering faster and cheaper transactions, along with support for various scaling solutions. On the other hand, a blockchain is a distributed and decentralized digital ledger that records transactions across multiple computers. It consists of blocks linked together in a chronological chain, where each block contains a set of transactions. Blockchains are known for their security, immutability, and transparency.</p>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex flex-col items-center w-full pb-8 pt-4'>
                <img src='hero.png' className='h-32' />
                <p className='txtgradient text-2xl font-bold'>Smart Contracts</p>
                <p className='mt-3 px-3 text-[#dad7d7]'>Smart contracts are self-executing contracts with the terms of the agreement directly written into code. They are built on blockchain platforms like Ethereum and are designed to automate and facilitate the execution of agreements without the need for intermediaries. Smart contracts work by enforcing the predefined rules and conditions of an agreement, ensuring that all parties involved fulfill their obligations. These contracts are stored and replicated across multiple nodes on a decentralized blockchain network, making them secure, transparent, and resistant to tampering.</p>
              </div>
            </div>
          </Carousel>
          </div>
        </div>

        {/* <div className='mt-16'>More</div> */}
     </div>
  )
}

export default Homepage
import React, { useContext, useEffect, useState } from 'react'
import { Appstate } from '../App'
import { Link, useLocation } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout';

const Sidebar = () => {
    const location = useLocation();
    const useAppState = useContext(Appstate)
    const [rank, setRank] = useState(0)
    let [isDownline, setIsDownline] = useState(false);

    useEffect(() => {
      let str = "/down";
      let path = location.pathname;
      for(let i=0; i<str.length; i++) {
        if(path[i] == str[i]) {
          if(i==str.length-1) {
            setIsDownline(true);
          }
        } else {
          break;
        }
      }
      async function getData() {
        let user = await useAppState.contract.userInfo(useAppState.walletAddress);
        setRank(Number(user.rank));
      }
      getData();
    },[])

  return (
    <aside
        id="default-sidebar"
        class={`z-40 w-full md:w-64 fixed md:sticky md:top-0 bottom-0 overflow-y-auto h-screen/2 md:h-screen transition-all ${useAppState.show ? 'visible' : 'hidden'}`}
        aria-label="Sidebar"
      >
        <div class="h-full px-5 py-6 transition-all md:px-3 md:py-4 overflow-y-auto rounded-lg md:rounded-none bg-black md:bg-[#8080821a]">
            <p class="text-slate-400 text-sm mb-2 ml-1">Welcome back,</p>
            <a href="#" class="flex flex-col space-y-2 md:space-y-0 md:flex-row mb-5 items-center md:space-x-2 bg-white/10 group transition duration-150 ease-linear rounded-lg group w-full py-3 px-2">
                <div className='rounded-full bg-orange-500 flex justify-center items-center h-12 w-12 font-semibold'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                  </svg>
                </div>
                <div>
                    <p class="font-medium group-hover:text-indigo-400 leading-4">{useAppState.walletAddress.slice(0, 6)}...{useAppState.walletAddress.slice(38)}</p>
                    <span class="text-xs text-slate-400">{useAppState.rank[rank]}</span>
                </div>
            </a>
          <ul class="space-y-2 font-medium">
            <Link onClick={() => useAppState.setShow(useAppState.isPc ? true : false)} to={'/dashboard'} ><li className='mt-4'>
              <a
                href="#"
                class={`${location.pathname == '/dashboard' ? "bg-gray-500 bg-opacity-20" : ""} flex items-center p-2 text-white rounded-lg hover:bg-gray-500 hover:bg-opacity-20 group`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-window-sidebar" viewBox="0 0 16 16">
                  <path d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
                  <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1zM1 13V6h4v8H2a1 1 0 0 1-1-1m5 1V6h9v7a1 1 0 0 1-1 1z"/>
                </svg>
                <span class="ms-3">Dashboard</span>
              </a>
            </li></Link>

            <Link onClick={() => useAppState.setShow(useAppState.isPc ? true : false)} to={'/teams'} ><li className='mt-4'>
              <a
                href="#"
                class={`${location.pathname == '/teams' ? "bg-gray-500 bg-opacity-20" : ""} flex items-center p-2 text-white rounded-lg hover:bg-gray-500 hover:bg-opacity-20 group`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-microsoft-teams" viewBox="0 0 16 16">
                    <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0m-2.238 9.488-.12-.002a5.2 5.2 0 0 0 .381-2.07V6.306a1.7 1.7 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.6 2.6 0 0 1-2.598 2.598z"/>
                    <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.68.68 0 0 1-.682.682H.682A.68.68 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945z"/>
                </svg>
                <span class="ms-3">Teams Info</span>
              </a>
            </li></Link>

            <p onClick={() => useAppState.setShow(useAppState.isPc ? true : false)} ><li className='mt-4'>
              <a
                href="/downlineusers/1"
                class={`${isDownline ? "bg-gray-500 bg-opacity-20" : ""} flex items-center p-2 text-white rounded-lg hover:bg-gray-500 hover:bg-opacity-20 group`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-layer-backward" viewBox="0 0 16 16">
                  <path d="M8.354 15.854a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708l1-1a.5.5 0 0 1 .708 0l.646.647V4H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9v7.793l.646-.647a.5.5 0 0 1 .708 0l1 1a.5.5 0 0 1 0 .708z"/>
                  <path d="M1 9a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4.5a.5.5 0 0 1 0 1H1v2h4.5a.5.5 0 0 1 0 1zm9.5 0a.5.5 0 0 1 0-1H15V6h-4.5a.5.5 0 0 1 0-1H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1z"/>
                </svg>
                <span class="ms-3">Downline Users</span>
              </a>
            </li></p>

            <Link onClick={() => useAppState.setShow(useAppState.isPc ? true : false)} to={'/deposithistory'} ><li className='mt-4'>
              <a
                href="#"
                class={`${location.pathname == '/deposithistory' ? "bg-gray-500 bg-opacity-20" : ""} flex items-center p-2 text-white rounded-lg hover:bg-gray-500 hover:bg-opacity-20 group`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                  <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
                  <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
                  <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
                </svg>
                <span class="ms-3">Order History</span>
              </a>
            </li></Link>

            <Link onClick={() => useAppState.setShow(useAppState.isPc ? true : false)} to={'/wallet'} ><li className='mt-4'>
              <a
                href="#"
                class={`${location.pathname == '/wallet' ? "bg-gray-500 bg-opacity-20" : ""} flex items-center p-2 text-white rounded-lg hover:bg-gray-500 hover:bg-opacity-20 group`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet-fill" viewBox="0 0 16 16">
                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z"/>
                    <path d="M16 6.5h-5.551a2.7 2.7 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z"/>
                </svg>
                <span class="ms-3">Wallet</span>
              </a>
            </li></Link>

            <Link onClick={() => useAppState.setShow(useAppState.isPc ? true : false)} to={'/swap'} ><li className='mt-4'>
              <a
                href="#"
                class={`${location.pathname == '/swap' ? "bg-gray-500 bg-opacity-20" : ""} flex items-center p-2 text-white rounded-lg hover:bg-gray-500 hover:bg-opacity-20 group`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-nintendo-switch" viewBox="0 0 16 16">
                  <path d="M9.34 8.005c0-4.38.01-7.972.023-7.982C9.373.01 10.036 0 10.831 0c1.153 0 1.51.01 1.743.05 1.73.298 3.045 1.6 3.373 3.326.046.242.053.809.053 4.61 0 4.06.005 4.537-.123 4.976-.022.076-.048.15-.08.242a4.14 4.14 0 0 1-3.426 2.767c-.317.033-2.889.046-2.978.013-.05-.02-.053-.752-.053-7.979m4.675.269a1.62 1.62 0 0 0-1.113-1.034 1.61 1.61 0 0 0-1.938 1.073 1.9 1.9 0 0 0-.014.935 1.63 1.63 0 0 0 1.952 1.107c.51-.136.908-.504 1.11-1.028.11-.285.113-.742.003-1.053M3.71 3.317c-.208.04-.526.199-.695.348-.348.301-.52.729-.494 1.232.013.262.03.332.136.544.155.321.39.556.712.715.222.11.278.123.567.133.261.01.354 0 .53-.06.719-.242 1.153-.94 1.03-1.656-.142-.852-.95-1.422-1.786-1.256"/>
                  <path d="M3.425.053a4.14 4.14 0 0 0-3.28 3.015C0 3.628-.01 3.956.005 8.3c.01 3.99.014 4.082.08 4.39.368 1.66 1.548 2.844 3.224 3.235.22.05.497.06 2.29.07 1.856.012 2.048.009 2.097-.04.05-.05.053-.69.053-7.94 0-5.374-.01-7.906-.033-7.952-.033-.06-.09-.063-2.03-.06-1.578.004-2.052.014-2.26.05Zm3 14.665-1.35-.016c-1.242-.013-1.375-.02-1.623-.083a2.81 2.81 0 0 1-2.08-2.167c-.074-.335-.074-8.579-.004-8.907a2.85 2.85 0 0 1 1.716-2.05c.438-.176.64-.196 2.058-.2l1.282-.003v13.426Z"/>
                </svg>
                <span class="ms-3">BTCHSwap</span>
              </a>
            </li></Link>

            {/* <Link onClick={() => useAppState.setShow(useAppState.isPc ? true : false)} to={'/admin'} ><li className='mt-4'>
              <a
                href="#"
                class={`${location.pathname == '/admin' ? "bg-gray-500 bg-opacity-20" : ""} flex items-center p-2 text-white rounded-lg hover:bg-gray-500 hover:bg-opacity-20 group`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-amazon" viewBox="0 0 16 16">
                  <path d="M10.813 11.968c.157.083.36.074.5-.05l.005.005a90 90 0 0 1 1.623-1.405c.173-.143.143-.372.006-.563l-.125-.17c-.345-.465-.673-.906-.673-1.791v-3.3l.001-.335c.008-1.265.014-2.421-.933-3.305C10.404.274 9.06 0 8.03 0 6.017 0 3.77.75 3.296 3.24c-.047.264.143.404.316.443l2.054.22c.19-.009.33-.196.366-.387.176-.857.896-1.271 1.703-1.271.435 0 .929.16 1.188.55.264.39.26.91.257 1.376v.432q-.3.033-.621.065c-1.113.114-2.397.246-3.36.67C3.873 5.91 2.94 7.08 2.94 8.798c0 2.2 1.387 3.298 3.168 3.298 1.506 0 2.328-.354 3.489-1.54l.167.246c.274.405.456.675 1.047 1.166ZM6.03 8.431C6.03 6.627 7.647 6.3 9.177 6.3v.57c.001.776.002 1.434-.396 2.133-.336.595-.87.961-1.465.961-.812 0-1.286-.619-1.286-1.533M.435 12.174c2.629 1.603 6.698 4.084 13.183.997.28-.116.475.078.199.431C13.538 13.96 11.312 16 7.57 16 3.832 16 .968 13.446.094 12.386c-.24-.275.036-.4.199-.299z"/>
                  <path d="M13.828 11.943c.567-.07 1.468-.027 1.645.204.135.176-.004.966-.233 1.533-.23.563-.572.961-.762 1.115s-.333.094-.23-.137c.105-.23.684-1.663.455-1.963-.213-.278-1.177-.177-1.625-.13l-.09.009q-.142.013-.233.024c-.193.021-.245.027-.274-.032-.074-.209.779-.556 1.347-.623"/>
                </svg>
                <span class="ms-3">Admin</span>
              </a>
            </li></Link> */}

          </ul>
          <Link onClick={() => useAppState.setShow(useAppState.isPc ? true : false)} to={'/'}><button className='bg-red-500 mt-5 w-full rounded-lg p-2 text-white font-medium'><LogoutIcon /> Logout</button></Link>
        </div>
    </aside>
  )
}

export default Sidebar
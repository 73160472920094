import InfoIcon from '@mui/icons-material/Info';
import { useContext, useEffect, useState } from 'react';
import {Drawer} from 'antd'
import { Appstate } from '../App';
import { Pie, Doughnut, Radar } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale, PointElement, LineElement, Filler } from "chart.js";
import LqGraph from './LqGraph';
import {TailSpin} from 'react-loader-spinner'

ChartJS.register(ArcElement, Tooltip, Legend, RadialLinearScale, PointElement, LineElement, Filler);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 100,
      }
    },
    plugins: {
        legend: {
          display: false // Hide the legend
        }
    }
  };

  const lqOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
          position: 'right', // Position the legend to the right of the chart
          align: 'center', // Align the legend items to the start (top) of the legend box
          labels: {
            padding: 10, // Add padding between legend items
            boxHeight: 20, // Set the height of each legend item
            usePointStyle: true // Use point style for legend items (if applicable)
          }
        }
      }
  };

const TokenInfo = () => {
    const useAppState = useContext(Appstate)
    const [open, setOpen] = useState(false);
    const [bal, setBal] = useState([0,0,0]);
    const [show, setShow] = useState([false, false]);
    const [totalSupply, setTotalSupply] = useState(0);
    const [burned, setBurned] = useState(0);
    const [data, setData] = useState({
        labels: ['Rewards', 'Liquidity', 'Holders'],
        datasets: [
          {
            label: 'Allocated (%) ',
            data: [50, 30, 20],
            backgroundColor: 'rgba(255, 99, 132, 0.2)', // Background color for the area
            borderColor: 'rgba(255, 99, 132, 1)', // Border color
            borderWidth: 1, // Border width
            fill: true // Fill the area under the radar lines
          }
        ]
      })

      const [lqData, setLqData] = useState({
        labels: ['Total Supply', 'Total Burned'],
        datasets: [{
          label: 'BTCH Amount ',
          data: [100, 1],
          backgroundColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)',
          ]
        }]
      });

      useEffect(() => {
            async function getData() {
                let _supply = await useAppState.tokenContract2.totalSupply();
                setTotalSupply(Number(useAppState.convert(_supply)));
                let _bal = await useAppState.tokenContract2.balanceOf("0xD21fb7211719720d1353EaFD72a4a7d4774777C1")
                let _bal2 = await useAppState.tokenContract2.balanceOf("0xE856A742d63ABa9bD7E63C2530fD194075F71F47")
                setBal([Number(useAppState.convert(_bal)).toFixed(2), Number(useAppState.convert(_bal2)).toFixed(2), (Number(useAppState.convert(_supply)) - (Number(useAppState.convert(_bal)) + Number(useAppState.convert(_bal2))))]);
                setData({
                    labels: ['Rewards', 'Liquidity', 'Holders'],
                    datasets: [
                      {
                        label: 'Allocated (%) ',
                        data: [Number(useAppState.convert(_bal)), Number(useAppState.convert(_bal2)), (Number(useAppState.convert(_supply)) - (Number(useAppState.convert(_bal)) + Number(useAppState.convert(_bal2))))],
                        backgroundColor: 'rgba(255, 99, 132, 0.2)', // Background color for the area
                        borderColor: 'rgba(255, 99, 132, 1)', // Border color
                        borderWidth: 1, // Border width
                        fill: true // Fill the area under the radar lines
                      }
                    ]
                  })
                  setShow([true, false]);
                setLqData({
                    labels: ['Total Supply', 'Total Burned'],
                    datasets: [{
                      label: 'BTCH Amount ',
                      data: [Number(useAppState.convert(_supply)), 1100000 - Number(useAppState.convert(_supply))],
                      backgroundColor: [
                          'rgba(54, 162, 235, 1)',
                          'rgba(255, 99, 132, 1)',
                      ]
                    }]
                  })
                  setShow([true, true]);
                  setBurned(1100000 - Number(useAppState.convert(_supply)))
            }
            getData();
      },[])

  return (
    <div className="mt-6 cursor-pointer w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg wallet">
        <div onClick={() => setOpen(true)} className='font-bold w-full pb-1 flex justify-between'>
          <p className='text-sm flex items-center'><img src='token.png' className='h-6 mr-2' /> BTCH Token Info</p>
          <p className='text-sm animate-pulse'><InfoIcon color='info' /></p>
        </div>

        <Drawer
          style={{ background: "white", opacity: "95%", color: "black", overflowY: "auto" }}
          onClose={() => setOpen(false)}
          open={open}
          closeIcon={false}
          placement={"left"}
          width={useAppState.isPc ? "30%" : '100%'}
        >   
          <p onClick={() => setOpen(false)} className="text-lg absolute top-4 right-4 cursor-pointer">❌</p>
          <p className='text-lg absolute top-4 left-4 font-mono font-semibold text-orange-500 flex items-center'><img src='token.png' className='h-6 mr-2' /> Token Info</p>
          <a href='https://polygonscan.com/token/0xfd96bf0a43ce3e676d064cd04035b3b849365656' target='_blank'><p className='text-gray-500 px-2 py-1 bg-gray-300 bg-opacity-60 mt-2 rounded-xl font-medium profile mb-2 cursor-pointer'>Token Contract Polygonscan: <span className='text-purple-500'>0xfd96...5656</span></p></a>
          <div className=' h-60'>
            {show[0] ?
            <Radar data={data} options={options} color='white' />
            : <TailSpin color='gray' height={20} />}
          </div>
          <div className='font-medium text-gray-500'>
            <p>🔸 <span className='text-green-500'>Rewards :</span> {Number((bal[0] * 100) / totalSupply).toFixed(1)}% <span className='text-gray-500'>({bal[0]} BTCH)</span></p>
            <p>🔸 <span className='text-yellow-500'>Liquidity :</span> {Number((bal[1] * 100) / totalSupply).toFixed(1)}% <span className='text-gray-500'>({bal[1]} BTCH)</span></p>
            <p>🔸 <span className='text-pink-500'>Holders :</span> {Number((bal[2] * 100) / totalSupply).toFixed(1)}% <span className='text-gray-500'>({Number(bal[2]).toFixed(2)} BTCH)</span></p>
          </div>
          <div className='border-b border-gray-400 border-opacity-60 w-full mt-4'></div>
          <div className='mt-4'>
            <h1 className='font-bold text-sm text-blue-500'>Liquidity Info</h1>
          </div>
          <LqGraph />

          <div className='border-b border-gray-400 border-opacity-60 w-full mt-4'></div>
          <div className='mt-4'>
            <h1 className='font-bold text-sm text-blue-500'>Token Supply</h1>
          </div>
          <div className='mt-4'>
            {show[1] ?
            <Doughnut data={lqData} options={lqOptions} />
            : <TailSpin color='gray' height={20} />}
          </div>
          <div className='font-medium text-gray-700 mt-6'>
            <p>🔸 <span className='text-teal-500'>Total Supply :</span> {totalSupply.toFixed(2)} BTCH</p>
            <p>🔸 <span className='text-red-500'>Total Burned :</span> {burned.toFixed(2)} BTCH</p>
        </div>
          <div className='border-b border-gray-400 border-opacity-60 w-full mt-4'></div>
        </Drawer>
    </div>
  )
}

export default TokenInfo
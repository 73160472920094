import { useContext, useState, useEffect, useRef } from "react";
import Header from "./Header"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Appstate} from '../App'
import CopyToClipboard from 'react-copy-to-clipboard';
import { message } from "antd";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Balance from "./Balance";
import { Link } from "react-router-dom";
import TelegramIcon from '@mui/icons-material/Telegram';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ProvideHelp from "./ProvideHelp";
import Sidebar from "./Sidebar";
import BlockIcon from '@mui/icons-material/Block';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {TailSpin} from 'react-loader-spinner'
import { useTimer } from "react-timer-hook";
import Requests from "./Requests";
import { ethers } from "ethers";
import moment from "moment";
import TokenInfo from "./TokenInfo";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

function MyTimer2({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days.toString()} Days</span>, <span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days.toString()} Days</span>, <span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const Dashboard = () => {
  const useAppState = useContext(Appstate);
  const [time, setTime] = useState(0);
  const [user, setUser] = useState({
    start: 0,
    referrer: "",
    rank: 0,
    generation: false,
    currentPackage: 0,
    growthIncome: 0,
    totalFreezed: 0,
    lostBonus: 0,
    active: false
  })
  const [isRankAvl, setIsRankAvl] = useState(false);
  const [rankLoading, setRankLoading] = useState(false);
  const [genAvl, setGenAvl] = useState(false);
  const [genLoading, setGenLoading] = useState(false);
  const [royaltyTime, setRoyaltyTime] = useState([0, 0]);
  const [royaltyShow, setRoyaltyShow] = useState([false, false]);
  const [roaylty, setRoyalty] = useState([0, 0]);
  const [lastDist, setLastDist] = useState([0, 0]);
  const [price, setPrice] = useState(0);
  const [txLoading, setTxLoading] = useState(false);
  const [roi, setRoi] = useState(0);
  const intervalRef = useRef(null);
  const incomeCap = 2;

  function calPer(a, b) {
    let per = parseInt((a*100)/b);
    return per > 100 ? 100 : per;
  }

  useEffect(() => { 
    async function getData() {
      let _price = await useAppState.swapContract.callStatic.getQuoteInUSDT(ethers.utils.parseUnits("1", "mwei"));
      setPrice(useAppState.convert(_price));

      let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
      if(Number(_user.start > 0)) {
      let _income = await useAppState.contract.income(useAppState.walletAddress);
      let _len = await useAppState.contract.getDepositLength(useAppState.walletAddress);
      let _active = await useAppState.contract.isOrderBlocked(useAppState.walletAddress, Number(_len) - 1)
      setUser({
        start: Number(_user.start),
        referrer: _user.referrer,
        rank: Number(_user.rank),
        generation: _user.generation,
        currentPackage: Number(useAppState.convert(_user.currentPackage)),
        growthIncome: Number(useAppState.convert(_income.growthIncome)),
        totalFreezed: Number(useAppState.convert(_user.totalFreezed)),
        lostBonus: Number(useAppState.convert(_income.lostGenBonus)),
        active: _active ? false : true
      })

      let _rankAvl = await useAppState.contract.isUpdateRankAvl(useAppState.walletAddress);
      let _genAvl = await useAppState.contract.isUpdateGenerationAvl(useAppState.walletAddress);
      setGenAvl(_genAvl)
      setIsRankAvl(_rankAvl);
      
      let len = await useAppState.contract.getDepositLength(useAppState.walletAddress);
        if(Number(len) > 0) {
          for(let i=Number(len) - 1; i>=0; i--) {
            let order = await useAppState.contract.depositHistory(useAppState.walletAddress, i);
            if(!order.isClaimed) {
              let _curROI = await useAppState.contract.getCurROI(useAppState.walletAddress, i);
              setRoi((prev) => prev + Number(useAppState.convert(_curROI)))
            }
          }
        }

    } else {
      setUser({...user, referrer: _user.referrer})
    }
    
      let active = await useAppState.contract.activeRoyalty();
      let global = await useAppState.contract.globalRoyalty();
      setRoyalty([useAppState.convert(active), useAppState.convert(global)]);

      let activeLastDist = await useAppState.contract.activeRoyaltyLastDist();
      let globalLastDist = await useAppState.contract.globalRoyaltyLastDist();
      setLastDist([Number(activeLastDist) * 1000, Number(globalLastDist) * 1000])

      let time = await useAppState.contract.getRoyaltyTimeRemaining();
      setRoyaltyTime([Number(time[0]) * 1000, Number(time[1]) * 1000]);
      setRoyaltyShow([true, true]);
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if(Number(user.start) > 0) {
        setTime(Date.now() - Number(user.start) * 1000);
      } else {
        setTime(0);
      }
    }, 10);
    return () => clearInterval(intervalRef.current);
  }, [user.start, useAppState.change, useAppState.walletAddress]);

  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
      return (
        `${days.toString().padStart(2, '0')} Days, ` +
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
  };

  const updateRank = async () => {
    setRankLoading(true);
    try {
      let tx = await useAppState.contract.updateRank(useAppState.walletAddress);
      await tx.wait();
      message.success("Rank Updated");
      useAppState.setChange(useAppState.change + 1);
    } catch (error) {
      message.error(error.reason) 
    }
    setRankLoading(false);
  }

  const updateGeneration = async () => {
    setGenLoading(true);
    try {
      let tx = await useAppState.contract.updateGeneration(useAppState.walletAddress);
      await tx.wait();
      message.success("Generation Bonus Updated");
      useAppState.setChange(useAppState.change + 1);
    } catch (error) {
      message.error(error.reason) 
    }
    setGenLoading(false);
  }

  const payPH = async (_index) => {
    if(checkUser()) {
      setTxLoading(true);
      try {
        let tx = await useAppState.contract.provideHelp(_index);
        await tx.wait();
        message.success("Sucessfully Paid");
        useAppState.setChange(useAppState.change + 1);
      } catch (error) {
        message.error(error.reason);
      }
      setTxLoading(false);
    } else {
      message.error("Service Unavailable, Working on it..")
    }
  }

  const claimReward = async (_index) => {
    setTxLoading(true);
    try {
        let tx = await useAppState.contract.requestClaim(_index);
        await tx.wait();
        message.success("Sucessfully Claimed");
        useAppState.setChange(useAppState.change + 1);
    } catch (error) {
        message.error("Donar ID Blocked");
        // message.error(error.reason);
    }
    setTxLoading(false);
  }

  const payLeftAmt = async (_index) => {
    if(checkUser()) {
      setTxLoading(true);
      try {
        let tx = await useAppState.contract.payLeftAmt(_index);
        await tx.wait();
        message.success("Sucessfully Paid");
        useAppState.setChange(useAppState.change + 1);
      } catch (error) {
        message.error(error.reason);
      }
      setTxLoading(false);
    } else {
      message.error("Service Unavailable, Working on it..")
    }
  }

  const checkUser = () => {
    let isUser = false;
    let arr = [
      "0xc2354eD50cb3f1be4cC0ea123C1a5C2BBC863D78",
      "0xc0598B52EEd05749B6E6200f8CB2C34e6d4D0Ae5",
      "0xCEcc00CD375c6aCE803C4719bE575a1797DaF843",
      "0x480a33454b681F3BCecaC59838de3761730AafD4"
    ];
    for(let i=0; i<arr.length; i++) {
      if(arr[i].toLowerCase() == useAppState.walletAddress.toLowerCase()) {
        isUser = true;
      }
    }
    return isUser; 
  }

  return (
    <div className="flex justify-center w-full">
      <Sidebar />
  <div className="flex justify-center p-4 md:w-4/5 w-full">
    <div className="w-full">
      <Requests />
      <Header />
      <Balance />
      <div className="mt-4 w-full flex flex-col md:flex-row items-center bg-[#8080821a] px-4 py-8 md:px-6 rounded-2xl shadow-lg profile">
        <img src={`b${user.rank}.png`} className=" h-32" />
        <div className="ml-0 md:ml-12 mt-4 md:mt-0">
          <p className="mt-2 text-lime-400 font-semibold">1 BTCH : <span className="font-semibold text-white bg-gray-500 bg-opacity-20 rounded-2xl px-3">{price} USDT</span></p>
          <p className="mt-2 text-lime-400 font-semibold">Account : <span className="font-semibold text-white bg-gray-500 bg-opacity-20 rounded-2xl px-3">{useAppState.walletAddress.slice(0,6)}...{useAppState.walletAddress.slice(38)} - {user.active ? <span className="text-green-500 animate-pulse">Active</span> : <span className="text-red-500 animate-pulse">InActive</span>}</span></p>
          <p className="mt-2 text-lime-400 font-semibold">Current Package : <span className="font-semibold text-white bg-gray-500 bg-opacity-20 rounded-2xl px-3">${user.currentPackage}</span></p>
          <div className="mt-2 font-semibold"><span className="text-lime-400 font-medium">Member : </span>Since <span className="text-gray-300 bg-gray-500 bg-opacity-20 rounded-2xl px-3">{formatTime(time)}</span> by <span className="text-gray-300 bg-gray-500 bg-opacity-20 rounded-2xl px-3">{user.referrer.slice(0, 6) + "..." + user.referrer.slice(38)}</span></div>
          <p className="font-semibold break-all mt-2"><span className="text-lime-400 font-medium">Refer Link : </span><span className="bg-gray-500 text-gray-300 bg-opacity-20 rounded-2xl px-2">btchelp.live/register?ref={useAppState.walletAddress.slice(0,6)}...{useAppState.walletAddress.slice(38)}</span>
            <CopyToClipboard text={`https://btchelp.live/register?ref=${useAppState.walletAddress}`} >
              <FileCopyIcon onClick={() => message.success("Copied")} fontSize="small" color="primary" className="ml-2 mr-2 cursor-pointer" />
            </CopyToClipboard>
          </p>
        </div>
      </div>

      <TokenInfo />

      {/* Pending Requests */}
      {useAppState.req.map((e, i) => {
            return (
                <div key={i} className='mt-4 rounded-lg p-2 font-mono bg-[#8080821a] profile'>
                    <p className='text-center text-red-500 font-medium'>{useAppState.walletAddress.toLowerCase() == e.donar.toLowerCase() ? <p className=" text-cyan-500">Provide Help</p> : <p className=" text-pink-500">Get Help</p>}</p>
                    <div className="flex w-full flex-col items-center justify-center text-4xl">
                      <AccessAlarmIcon className="mt-2" fontSize="inherit" color={moment().valueOf() < (e.endTime - moment(0).add(4, "minutes").valueOf()) ? "secondary" : "warning"} />
                        <span className='inline-block text-white text-lg'><MyTimer expiryTimestamp={e.isCompleted ? 0 : e.endTime} /></span>
                        <span className='text-white text-lg'>{e.amtPaid ? "Success" : (moment().valueOf() > e.endTime ? <p className="text-red-500">Expired</p> : <p className="text-orange-500">Pending</p>)}</span>
                    </div>
                    <div className='p-2'>
                        <p className='text-lime-500'>Amount : <span className='text-white'>${e.amount}</span></p>
                        <p className='text-lime-500'>Donar : <span className='text-white break-all'>{e.donar}</span></p>
                        <p className='text-lime-500'>Receiver : <span className='text-white break-all'>{e.receiver}</span></p>
                        <p className='text-lime-500'>Started : <span className='text-white'>{new Date(e.start).toLocaleString()}</span></p>
                    </div>

                  {moment().valueOf() >= e.endTime && !e.isCompleted && useAppState.walletAddress.toLowerCase() == e.receiver.toLowerCase() ?
                    <div className="p-2 flex justify-end">
                      <button onClick={() => claimReward(e.globalIndex)} className="ml-3 bg-red-500 text-white px-3 py-1 rounded-sm">{txLoading ? <TailSpin height={14} color="white" /> : "Claim"}</button>
                    </div>
                    : null
                  }

                  {e.isCompleted || moment().valueOf() >= e.endTime || useAppState.walletAddress.toLowerCase() == e.receiver.toLowerCase() ? null :
                    <div className="p-2 flex justify-end">  
                      { e.isAuto ?
                        <button onClick={() => payLeftAmt(e.sno)} className="bg-green-500 text-white px-3 py-1 rounded-sm">{txLoading ? <TailSpin height={14} color="white" /> : "Pay"}</button>
                        : <button onClick={() => payPH(e.globalIndex)} className="bg-green-500 text-white px-3 py-1 rounded-sm">{txLoading ? <TailSpin height={14} color="white" /> : "Pay"}</button>
                      }
                    </div>
                  } 

                  {e.donar.toLowerCase() === e.receiver.toLowerCase() && moment().valueOf() < e.endTime ?
                    <div className="p-2 flex justify-end">  
                    <button onClick={() => payPH(e.globalIndex)} className="bg-green-500 text-white px-3 py-1 rounded-sm">{txLoading ? <TailSpin height={14} color="white" /> : "Pay"}</button>
                    </div>
                    : null}

                  {e.isAuto && moment().valueOf() >= e.endTime ?
                    <div className="p-2 flex justify-end">  
                    <button onClick={() => payLeftAmt(e.sno)} className="bg-green-500 text-white px-3 py-1 rounded-sm">{txLoading ? <TailSpin height={14} color="white" /> : "Update"}</button>
                    </div>
                    : null
                  }
                </div>
        )})}

      {/* Growth Income Limit */}
      <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg autopool">
          <div className='font-bold w-full pb-1'>
            <p className='text-sm'>Growth Income Limit (${user.growthIncome}/${user.totalFreezed * incomeCap})</p>
            <p className='w-full bg-gray-400 h-2 mt-1 rounded-xl'><p style={{width: `${calPer(user.growthIncome, user.totalFreezed * incomeCap)}%`}} className={`max-w-full w-[${calPer(user.growthIncome, user.totalFreezed * incomeCap)}%] bg-green-500 h-full rounded-xl`}></p></p>
          </div>
      </div>

    {/* ROI Income */}
    {user.currentPackage > 0 ?
      <div className="mt-4 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg wallet">
          <div className='font-bold w-full pb-1 flex justify-between items-center'>
            <p className='font-bold'>ROI <span className='text-lime-500'>Generated </span></p>
            <p className="font-mono font-bold text-lg">${roi}</p>
          </div>
      </div>
    : null}

    {/* Lost Gen Bonus */}
    {user.lostBonus > 0 ?
      <div className="mt-4 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg wallet">
          <div className='font-bold w-full pb-1 flex justify-between items-center'>
            <p className='font-bold'>Lost <span className='text-red-500 animate-pulse'>Income </span></p>
            <p className="font-mono font-bold text-lg">${user.lostBonus}</p>
          </div>
      </div>
    : null} 

      <ProvideHelp />

      {/* Manager Update */}
      <div className="flex flex-col md:flex-row justify-between mt-5">
        <div className="w-full md:w-1/2 p-4 bg-[#8080821a] rounded-lg shadow-lg wallet">
          <p className='font-bold'>Next <span className='text-green-500'>Manager Rank</span></p>
          <div className="flex mt-2 items-center justify-between">
            <img src={`b${user.rank < 6 ? user.rank + 1 : user.rank}.png`} className=" h-32" />
            {user.rank < 6 ?
            <p>{isRankAvl ? <span onClick={updateRank} className="px-8 md:px-16 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black flex justify-center items-center cursor-pointer">{rankLoading ? <TailSpin color="white" height={18} /> : <p>Update Rank <ThumbUpAltIcon className="ml-3" /> </p>}</span> : <span className="px-8 md:px-16 py-4 text-sm font-semibold rounded-md bg-[#e5573e] text-white flex justify-center items-center cursor-not-allowed">Unqualified <BlockIcon className="ml-3" /></span>}</p>
            :
            <p className="px-8 md:px-16 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black flex justify-center items-center cursor-pointer">All Qualified</p> 
            }
          </div>
        </div>
        <div className="md:mt-0 mt-4 md:ml-4 ml-0 w-full md:w-1/2 p-4 bg-[#8080821a] rounded-lg shadow-lg wallet">
          <p className='font-bold'>Update <span className='text-green-500'>Generation Bonus</span></p>
          <div className="flex mt-2 items-center justify-between">
            <img src={`generation.png`} className=" h-32" />
            {user.generation ?
              <p className="px-8 md:px-16 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black flex justify-center items-center cursor-pointer">Generation Active</p>
            :  <p>{genAvl ? <span onClick={updateGeneration} className="px-8 md:px-16 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black flex justify-center items-center cursor-pointer">{genLoading ? <TailSpin color="white" height={18} /> : <p>Update Generation <ThumbUpAltIcon className="ml-3" /> </p>}</span> : <span className="px-8 md:px-16 py-4 text-sm font-semibold rounded-md bg-[#e5573e] text-white flex justify-center items-center cursor-not-allowed">Unqualified <BlockIcon className="ml-3" /></span>}</p>
            }
          </div>
        </div>
      </div>

      {/* Royalty */}
      <div className="flex mb-6 flex-col md:flex-row justify-between mt-6">
        <div className='flex flex-col items-center relative  h-44 w-full rounded-xl md:w-1/2'>
          <img src='../lotter-background.png' className='absolute top-0 h-full w-full rounded-xl -z-10' />
          <p className='text-md font-semibold mt-3'>Active Roaylty Pool</p>
          <p className='text-3xl text-[#34d178] font-bold flex justify-center items-center'>${roaylty[0]} <img src='usdt.png' className='h-6 ml-2' /></p>
          <p className='text-xs mt-1 text-[#e67deb] font-semibold'>Time Remaining</p>
          <p className='text-3xl font-mono font-bold'>{royaltyShow[0] ? <MyTimer2 expiryTimestamp={royaltyTime[0]} /> : null}</p>
          <p className='text-xs mt-1 text-white font-semibold'>Last Distributed on {new Date(lastDist[0]).toLocaleString()}</p>
        </div>
        <div className='mt-6 md:mt-0 ml-0 md:ml-4 flex flex-col justify-center rounded-xl items-center relative  h-44 w-full md:w-1/2'>
          <img src='../lotter-background.png' className='absolute top-0 -z-10 h-full w-full rounded-xl' />
          <p className='text-md font-bold'>Global Royalty Pool</p>
          <p className='text-3xl text-[#34d178] font-bold flex justify-center items-center'>${roaylty[1]} <img src='usdt.png' className='h-6 ml-2' /></p>
          <p className='text-xs mt-1 text-[#f0c259] font-semibold'>Time Remaining</p>
          <p className='text-3xl font-mono font-bold'>{royaltyShow[1] ? <MyTimer2 expiryTimestamp={royaltyTime[1]} /> : null}</p>
          <p className='text-xs mt-1 text-white font-semibold'>Last Distributed on {new Date(lastDist[1]).toLocaleString()}</p>
        </div>
      </div>

    </div>
  </div>
  </div>
  )
}

export default Dashboard

import React, { useContext, useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import Sidebar from './Sidebar'
import Requests from './Requests'
import Header from './Header'
import { Appstate } from '../App'
import {message} from 'antd';
import moment from 'moment'
import {ethers} from 'ethers'
import LockerABI from '../artifacts/Locker/Lock.sol/Locker.json'

function MyTimer({ expiryTimestamp }) {
    const {
      seconds,
      minutes,
      hours,
    } = useTimer({ expiryTimestamp});
  
    return <p><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const Admin = () => {
    const useAppState = useContext(Appstate);
    const [req, setReq] = useState([{
      sno: 0,
      donar: "",
      receiver: "",
      amount: 0,
      start: 0,
      isCompleted: false,
      depositIndex: 0,
      globalIndex: 0,
      endTime: 0,
      amtPaid: false
    }])
    
    const [gh, setGH] = useState(0);
    const [ph, setPH] = useState(0);
    const [lockTime, setLockTime] = useState(0);

    let lockerAddr = "0xa6840090fCFf677376Ed4759D73448cc9104617d"
    let tokenId = 1338905;
    let lockDuration = (moment(0).add(5, "years").valueOf())/1000;

    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner();
    let ERC721ABI = ["function approve(address to, uint256 tokenId) external"];
    let contract = new ethers.Contract("0xC36442b4a4522E871399CD717aBDD847Ab11FE88", ERC721ABI, signer);
    let locker = new ethers.Contract(lockerAddr, LockerABI.abi, signer);

    useEffect(() => {
        async function getData() {
            setReq([]);
            setGH(useAppState.convert(await useAppState.contract.GHAmt()));
            setPH(useAppState.convert(await useAppState.contract.PHAmt()));
            let reqLength = await useAppState.contract.getAPHReqLength();
            if(Number(reqLength) > 0) {
                for(let i=Number(reqLength - 1); i>=0; i--) {
                    let _index = await useAppState.contract.AdRequests(i);
                    let userHistory = await useAppState.contract.globalRequests(_index);
                    setReq((prev) => [...prev, {
                      sno: i,
                      donar: userHistory.donar,
                      receiver: userHistory.receiver,
                      amount: useAppState.convert(userHistory.amount),
                      start: Number(userHistory.start) * 1000,
                      isCompleted: userHistory.isCompleted,
                      depositIndex: Number(userHistory.depositIndex),
                      globalIndex: Number(userHistory.globalIndex),
                      endTime: Number(userHistory.endTime) * 1000,
                      amtPaid: userHistory.amtPaid
                    }]);
                }
            }
            let _time = await locker.unlockTime()
            setLockTime(Number(_time) * 1000);
        }
        getData();
    },[useAppState.walletAddress, useAppState.change])


    const distActiveRoyalty = async () => {
        try {
          let tx = await useAppState.contract.distributeActiveRoyalty();
          await tx.wait();
          message.success("Sucessfully Distributed");
          useAppState.setChange(useAppState.change + 1);
        } catch (error) {
          message.error(error.reason) 
        } 
      }
    
      const distGlobalRoyalty = async () => {
        try {
          let tx = await useAppState.contract.distributeGlobalRoyalty();
          await tx.wait();
          message.success("Sucessfully Distributed");
          useAppState.setChange(useAppState.change + 1);
        } catch (error) {
          message.error(error.reason) 
        } 
      }

      const payAPH = async (_index) => {
        try {
            let tx = await useAppState.contract.payAPH([_index]);
            await tx.wait();
            message.success("Sucessfully Paid");
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            message.error(error.reason);
        }
      }

      const distAdminFee = async () => {
        try {
            let tx = await useAppState.feeContract.distUsdt();
            await tx.wait();
            message.success("Sucessfully Distributed");
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            message.error(error.reason);
        }
      }

      const Lock = async () => {
        try {
           let allow = await contract.approve(lockerAddr, tokenId)
           await allow.wait();
           message.success("Sucessfully Approved");
           
            let lock = await locker.Lock(tokenId, lockDuration);
            await lock.wait();
            message.success("Sucessfully Locked");
        } catch (error) {
            message.error(error.reason);
        }
      }

      const Unlock = async () => {
        try {
            let unlock = await locker.Withdraw(tokenId);
            await unlock.wait();
            message.success("Sucessfully Unlocked");
        } catch (error) {
            message.error(error.reason);
        }
      }

  return (
    <div className="flex justify-center w-full">
        <Sidebar />
        <div className="flex justify-center p-4 md:w-4/5 w-full">
            <div className="w-full">
                {/* <Requests /> */}
                <Header />
                <div className='mt-6'>
                  <p>Total Get Help Amount : {gh}</p>
                  <p>Total Provide Help Amount : {ph}</p>
                </div>

                <div className='mt-6'>
                    <button onClick={distActiveRoyalty} className="mt-6 ml-6 bg-green-500 rounded-lg py-2 px-5">Distribute active Royalty</button>
                    <button onClick={distGlobalRoyalty} className="mt-6 ml-6 bg-green-500 rounded-lg py-2 px-5">Distribute Global Royalty</button>
                </div>

                <div className='mt-6'>
                    <button onClick={distAdminFee} className="mt-6 ml-6 bg-green-500 rounded-lg py-2 px-5">Distribute Admin Fee</button>
                </div>

                {req.map((e, i) => {
            return (
                <div key={i} className='mt-4 rounded-lg p-2 border-lime-500 border-2 font-mono border-opacity-60 bg-[#8080821a]'>
                    <p className='text-center text-red-500 font-medium'>Provide Help</p>

                    <div className='p-2'>
                        <p className='text-lime-500'>Timer : <span className='inline-block text-white'><MyTimer expiryTimestamp={e.isCompleted ? 0 : e.endTime} /></span></p>
                        <p className='text-lime-500'>Amount : <span className='text-white'>${e.amount}</span></p>
                        <p className='text-lime-500'>Donar : <span className='text-white break-all'>{e.donar}</span></p>
                        <p className='text-lime-500'>Receiver : <span className='text-white break-all'>{e.receiver}</span></p>
                        <p className='text-lime-500'>Started : <span className='text-white'>{new Date(e.start).toLocaleString()}</span></p>
                        <p className='text-lime-500'>Status : <span className='text-white'>{e.amtPaid ? "Success" : (moment().valueOf() > e.endTime ? "Expired" : "Pending")}</span></p>
                    </div>

                  {e.isCompleted || moment().valueOf() >= e.endTime ? null :
                    <div className="p-2 flex justify-end">  
                      <button onClick={() => payAPH(e.globalIndex)} className="bg-green-500 text-white px-3 py-1 rounded-sm">Pay</button>
                    </div>
                  } 
                </div>
        )})}

        <div>
            
        <div className='mt-6'>
          <p>Liquidity Locked Till: {new Date(lockTime).toLocaleString()}</p>
          <button onClick={Lock} className="mt-6 ml-6 bg-green-500 rounded-lg py-2 px-5">Lock Liquidity</button>
          <button onClick={Unlock} className="mt-6 ml-6 bg-orange-500 rounded-lg py-2 px-5">Withdraw Liquidity</button>
        </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Admin
import React, { useContext, useEffect, useState } from 'react'
import {TailSpin, ThreeDots} from 'react-loader-spinner'
import { message } from 'antd';
import { Appstate } from '../App';
import { ethers } from 'ethers';

const ProvideHelp = () => {
  const useAppState = useContext(Appstate);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false)
  const [amtBtch, setAmtBtch] = useState(0);
  const [change, setChange] = useState(0);
  const [priceLoading, setPriceLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setPriceLoading(true);
      if(amount !== "") {
        let _price = await useAppState.swapContract.callStatic.getQuoteInBTCH(ethers.utils.parseUnits(amount, "mwei"));
        setAmtBtch(useAppState.convert(_price));
      }
      setPriceLoading(false);
    }
    getData();
  },[change])

  const deposit = async () => {
    setLoading(true);
    try {
      let _allowance = await useAppState.tokenContract.allowance(useAppState.walletAddress,useAppState.mainAddr);
      if(Number(useAppState.convert(_allowance)) <= 0) {
        let _tx = await useAppState.tokenContract.approve(useAppState.mainAddr, ethers.utils.parseUnits("100000000", "mwei"))
        await _tx.wait();
        message.success("Sucessfully Approved");
      }
      let _allowance2 = await useAppState.tokenContract2.allowance(useAppState.walletAddress,useAppState.mainAddr);
      if(Number(useAppState.convert(_allowance2)) <= 0) {
        let _tx = await useAppState.tokenContract2.approve(useAppState.mainAddr, ethers.utils.parseUnits("100000000", "mwei"))
        await _tx.wait();
        message.success("Sucessfully Approved");
      }
      let tx = await useAppState.contract.deposit(ethers.utils.parseUnits(amount, "mwei"));
      await tx.wait();
      message.success("Helping Completed");
      setAmount("");
      useAppState.setChange(useAppState.change + 1);
    } catch (error) {
      message.error(error.reason)
    }
    setLoading(false);
  }

  return (
    <div className="mt-4 w-full flex flex-col md:flex-row items-center bg-[#8080821a] px-4 py-4 md:px-6 rounded-2xl shadow-lg">
        <div className='flex flex-col-reverse md:flex-row w-full mt-3'>
            <div className='w-full md:w-3/5 mt-8 md:mt-0'>
                <div className='flex justify-between py-3 px-4 bg-gray-500 bg-opacity-20 rounded-xl'>
                <p className='flex flex-col items-center'>
                  <span className='text-lg font-bold flex justify-center items-center'>20 <img src='usdt.png' className='h-5 ml-2' /></span>
                  <span className=''>Minimum Helping</span>
                </p>
                <p className='flex flex-col items-center'>
                  <span className='text-lg font-bold flex justify-center items-center'>10,000 <img src='usdt.png' className='h-5 ml-2' /></span>
                  <span className=''>Maximum Helping</span>
                </p>
                </div>

                <p className='text-sm mt-6 px-5'>You cannot deposit a smaller amount in the current round compared to the previous round.</p>

                <p className='flex justify-between items-end px-2 md:px-4 py-3 rounded-lg mt-6'>
                  <input onChange={(e) => { setAmount(e.target.value); setChange(change + 1) }} value={amount} className='text-lg outline-none font-medium' style={{background: 'none'}} placeholder='Enter amount in USDT' />
                  <span className='text-green-500'>{amount == "" ? "Provide Help Amount" : (priceLoading ? <ThreeDots height={10} /> : <span>{amtBtch} BTCH</span>)}</span>
                </p>
                <div className='border border-[#76818b] w-full mt-2'></div>

                <div className='mt-6 w-full flex justify-center'>
                  <button onClick={deposit} className='ml-4 px-16 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black'>{loading ? <TailSpin height={15} color='white' /> : "Confirm"}</button>
                </div>
            </div>

            <div className='w-full flex flex-col items-center md:w-2/5 ml-0 md:ml-6'>
              <h1 className='mont text-lg text-teal-500 font-bold'>BTCHelp Helping Rule</h1>
              <img src='cycles.png' className='h-72 mt-4' />
            </div>
          </div>
    </div>
  )
}

export default ProvideHelp